import { useState } from "react";
import { PasswordInputProps } from "./type";

import EyeIcon from "../../../assets/svg/eyeIcon";

import "../input.scss";

const PasswordInput = (props: PasswordInputProps) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <div
        className={`form-control password-field  ${
          props.fieldClass ? props.fieldClass : ""
        } ${props.error ? "not-valid" : ""}`}
      >
        <input
          type="text"
          name={props.name || ""}
          id={props.id}
          placeholder={props.placeHolder}
          disabled={props.disabled || false}
          onChange={props.onChange}
          onClick={(e) => {
            e.stopPropagation();
          }}
          ref={null}
          value={props.value}
          className={`common-input ${props.style ? props.style : ""} ${
            isVisible ? "visible" : ""
          } ${props.prefix ? "ispreffix" : ""} `}
          autoFocus={props.autoFocus || false}
        />
        {props.prefix && <span className="input-prefix">{props.prefix}</span>}
        {props.error && <p className="error-message">{props.error}</p>}
        <span
          className={`toggle-visible ${isVisible ? "visible" : ""}`}
          onClick={(e) => {
            setIsVisible(!isVisible);
          }}
        >
          <EyeIcon />
        </span>
      </div>
    </>
  );
};

export default PasswordInput;
